// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-templates-404-js": () => import("./../../../src/templates/404.js" /* webpackChunkName: "component---src-templates-404-js" */),
  "component---src-templates-clients-js": () => import("./../../../src/templates/Clients.js" /* webpackChunkName: "component---src-templates-clients-js" */),
  "component---src-templates-collaborators-js": () => import("./../../../src/templates/Collaborators.js" /* webpackChunkName: "component---src-templates-collaborators-js" */),
  "component---src-templates-contact-js": () => import("./../../../src/templates/Contact.js" /* webpackChunkName: "component---src-templates-contact-js" */),
  "component---src-templates-culture-js": () => import("./../../../src/templates/Culture.js" /* webpackChunkName: "component---src-templates-culture-js" */),
  "component---src-templates-index-js": () => import("./../../../src/templates/Index.js" /* webpackChunkName: "component---src-templates-index-js" */),
  "component---src-templates-project-js": () => import("./../../../src/templates/Project.js" /* webpackChunkName: "component---src-templates-project-js" */),
  "component---src-templates-services-js": () => import("./../../../src/templates/Services.js" /* webpackChunkName: "component---src-templates-services-js" */),
  "component---src-templates-success-js": () => import("./../../../src/templates/Success.js" /* webpackChunkName: "component---src-templates-success-js" */),
  "component---src-templates-work-js": () => import("./../../../src/templates/Work.js" /* webpackChunkName: "component---src-templates-work-js" */)
}

